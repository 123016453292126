<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>前台配置</a-breadcrumb-item>
    <a-breadcrumb-item>自定义充值模板</a-breadcrumb-item>
  </a-breadcrumb>
  <div class="tool-view">
    <!-- 名称 -->
    <span class="tool-title">名称：</span>
    <a-input v-model:value="search" style="width: 160px;" placeholder="请输入模板名称" />
    <!-- 金额类型 -->
    <span class="tool-title" style="margin-left: 16px;">金额类型：</span>
    <a-select v-model:value="money_type" style="width: 120px;" placeholder="请选择">
      <a-select-option :value="0">全部</a-select-option>
      <a-select-option
        v-for="item in RechargeTemplateTypes"
        :key="item.value"
        :value="item.value"
      >
        {{ item.text }}
      </a-select-option>
    </a-select>
    <!-- 充值类型 -->
    <span class="tool-title" style="margin-left: 16px;">充值类型：</span>
    <a-select v-model:value="template_type" style="width: 120px;" placeholder="请选择">
      <a-select-option :value="0">全部</a-select-option>
      <a-select-option
        v-for="item in TemplateTypes"
        :key="item.value"
        :value="item.value"
      >
        {{ item.text }}
      </a-select-option>
    </a-select>
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="handleSearch">查询</a-button>
    <a-button style="margin-left: 16px;" @click="handleReset">重置</a-button>
    
    <div style="flex: 1;"></div>
    <!-- 新建 -->
    <a-button type="primary" @click="handleEdit()">
      <plus-outlined />
      创建模板
    </a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :loading="isLoading"
    :pagination="false"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record, index }">
      <!-- 设置 -->
      <template v-if="column.key === 'detail'">
        <div class="detail-content">
          <div v-for="(setting, ind) in record.detail" :key="ind" class="play-name">
            <!-- 虚拟币 -->
            <template v-if="setting.type === 'coin'">
              <!-- 第一项 -->
              {{ setting.money }}元
              <span class="gap">|</span>
              <!-- 第二项 -->
              立得{{ setting.coin }}{{ record.project.virtual_coin_name }}
              <!-- 第三项 -->
              <template v-if="setting.free_coin">
                <span class="gap">|</span>
                送{{ setting.free_coin }}{{ record.project.virtual_coin_name }}
              </template>
            </template>
            <!-- 全集解锁 -->
            <template v-if="setting.type === 'unlock'">
              <!-- 第一项 -->
              {{ setting.money }}元
              <span class="gap">|</span>
            </template>
            <!-- 全集解锁 -->
            <template v-if="setting.type === 'unlock'">
              <!-- 第一项 -->
              {{ setting.money }}元
              <!-- 第二项 -->
              <a-tag class="tag-type" v-if="setting.description">辅助语：{{ setting.description }}</a-tag>
            </template>
            <!-- 五折卡、会员、全集解锁 -->
            <template v-else>
              <!-- 第一项 -->
              {{ setting.days }}天<span class="gap">|</span>
              <!-- 第二项 -->
              {{ setting.money }}元
              <!-- 第三项 -->
              <a-tag class="tag-type" v-if="setting.description">辅助语：{{ setting.description }}</a-tag>
            </template>
            <!-- 辅助语 -->
            <a-tag class="tag-type" v-if="setting.is_help_message">辅助语：{{ setting.help_message }}</a-tag>
            <!-- 角标 -->
            <a-tag class="tag-type" v-if="setting.is_corner_on">角标：{{ setting.corner_name }}</a-tag>
            <!-- 气泡 -->
            <a-tag class="tag-type" v-if="setting.is_bubble_on">气泡：{{ setting.bubble_name }}</a-tag>
            <!-- 底色突出 -->
            <a-tag class="tag-type" v-if="setting.is_highlight">底色突出</a-tag>
            <!-- 手势引导 -->
            <a-tag class="tag-type" v-if="setting.is_gesture">手势引导</a-tag>
          </div>
        </div>
        <!-- 未配置时 -->
        <div v-if="!record.detail.length"> - </div>
      </template>
      <!-- 充值类型 -->
      <template v-if="column.key === 'is_first_charge'">
        <template v-for="item in TemplateTypes">
          <span v-if="item.value === record.is_first_charge">{{ item.text }}</span>
        </template>
      </template>
      <!-- 金额类型 -->
      <template v-if="column.key === 'money_type'">
        <template v-for="item in RechargeTemplateTypes">
          <span v-if="item.value === record.money_type">{{ item.text }}</span>
        </template>
      </template>
      <!-- 会员充值入口 -->
      <template v-if="column.key === 'is_vip'">
        {{ record[column.key] ? '开启' : '关闭'  }}
      </template>
      <!-- 会员充值入口 -->
      <template v-if="column.key === 'is_video'">
        {{ record[column.key] ? '开启' : '关闭'  }}
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <!-- 编辑 -->
        <a-button
          type="link"
          @click="handleEdit(record)"
        >
          编辑
        </a-button>
        <!-- 预览 -->
        <a-button
          type="link"
          @click="handlePreview(record)"
        >
          预览
        </a-button>
         <!-- 复制 -->
         <a-button
          type="link"
          @click="handleCopy(record)"
        >
          复制
        </a-button>
      </template>
    </template>
  </a-table>
  <!-- 单个编辑 -->
  <Edit ref="RefEdit" @success="getFrontSourceList"></Edit>
  <!-- 支付模版预览 -->
  <PayPreviewModal ref="RefPayPreviewModal"></PayPreviewModal>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import Edit from './components-coin-custom/Edit'
import PayPreviewModal from '@/views/operate/config/components-coin-custom/PreviewModal'
import { frontSourceUdList } from '@/api/operate'
import { RechargeTemplateTypes, TemplateTypes } from '@/utils/constantList'

// 编辑组件实例
let RefEdit = ref(null)
// 加载
let isLoading = ref(false)
// 支付模版预览
const RefPayPreviewModal = ref(null)
// 搜索
let search = ref(undefined)
let money_type = ref(undefined)
let template_type = ref(undefined)
// 数据源
let dataSource = ref([])
// 排序字段
let order = ref('id')
// 排序顺序
let orderType = ref('desc')
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源（付费转化率、权重本期不做）
let columns = ref([
  {
    title: '模板 ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    defaultSortOrder: 'descend'
  },
  {
    title: '模板名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '金额类型',
    dataIndex: 'money_type',
    key: 'money_type'
  },
  {
    title: '充值类型',
    dataIndex: 'is_first_charge',
    key: 'is_first_charge'
  },
  {
    title: '会员充值入口',
    dataIndex: 'is_vip',
    key: 'is_vip'
  },
  // {
  //   title: '视频集锦',
  //   dataIndex: 'is_video',
  //   key: 'is_video'
  // },
  {
    title: '创建人',
    dataIndex: 'create_username',
    key: 'create_username'
  },
  {
    title: '价格设定',
    dataIndex: 'detail',
    key: 'detail'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount(() => {
  // 获取配置列表
  getFrontSourceList()
})

// 查询
function handleSearch () {
  pagination.current = 1
  // 获取列表
  getFrontSourceList()
}

// 重置
function handleReset () {
  // 筛选项重置
  search.value = null
  money_type.value = undefined
  template_type.value = undefined
  // 分页重置
  handleSearch()
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  order.value = ''
  orderType.value = ''
  if (sorter.columnKey) {
    order.value = sorter.columnKey
    orderType.value = sorter.order === 'descend' ? 'desc' : 'asc'
  }
  getFrontSourceList()
}

// 获取配置列表
function getFrontSourceList () {
  isLoading.value = true
  frontSourceUdList({
    name: search.value,
    money_type: money_type.value,
    is_first_charge: template_type.value,
    // 固定参数
    source: 'charge',
    platform_id: 0,
    order: order.value,
    order_type: orderType.value,
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dataSource.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}

// 编辑
function handleEdit (record) {
  RefEdit.value.showDrawer(record ? {
    // id
    id: record.id,
    // 名称
    name: record.name
  } : {})
}

// 预览
function handlePreview (record) {
  RefPayPreviewModal.value.showModal(record.id)
}

// 复制
function handleCopy (record) {
  RefEdit.value.showDrawer({
    // id
    id: record.id,
    // 名称
    name: record.name
  }, true)
}

</script>

<style lang="less" scoped>
.tool-view {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.table-view {
  :deep(.ant-table-cell) {
    vertical-align: top;
  }
  .detail-content {
    width: 500px;
    overflow-x: auto;
    .play-name {
      white-space: nowrap;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .gap {
        margin: 0 8px;
      }
    }
  }
}
a[disabled] {
  pointer-events: none;
  cursor: default;
}
.tag-type {
  margin-left: 20px;
  background: #eee;
}
.tag-type-pro {
  margin-left: 0px;
  margin-right: 20px;
}
</style>